import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "min-h-full" }
const _hoisted_2 = { class: "flex-auto pb-24" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_login_modal = _resolveComponent("login-modal")!
  const _component_footer_nav = _resolveComponent("footer-nav")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("main", _hoisted_2, [
      _createVNode(_component_login_modal)
    ]),
    _createElementVNode("footer", null, [
      _createVNode(_component_footer_nav)
    ])
  ]))
}