import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "items-center justify-between bg-grayscale-14 px-6 py-6 sm:flex sm:px-24" }
const _hoisted_2 = { class: "flex justify-center" }
const _hoisted_3 = { class: "w-full sm:max-w-md" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cf_tabs = _resolveComponent("cf-tabs")!
  const _component_cf_tool_bar = _resolveComponent("cf-tool-bar")!
  const _component_cf_search_bar = _resolveComponent("cf-search-bar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_cf_tabs, { tabs: _ctx.tabs }, null, 8, ["tabs"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_cf_tool_bar, { toolbar: _ctx.toolbar }, null, 8, ["toolbar"])
    ]),
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_cf_search_bar, { placeholder: "Search project" })
      ])
    ])
  ]))
}