
import { defineComponent, ref, PropType } from "@cloudfun/core";
import CfTabs from "@/components/tabs/Main.vue";
import CfSearchBar from "@/components/search-bar/Main.vue";
import CfToolBar from "@/components/tool-bar/Main.vue";
import SharingGroupModal from "@/components/modal/sharing-group/Main.vue";

export default defineComponent({
  components: {
    CfTabs,
    CfSearchBar,
    CfToolBar,
    // SharingGroupModal,
  },
  props: {},
  setup() {
    // const modalSharingGroupOpen = ref(false);

    // function openSharingGroupModal() {
    //   console.log("OPEN");
    //   modalSharingGroupOpen.value = true;
    // }

    // function closeModal() {
    //   modalSharingGroupOpen.value = false;
    // }

    return {
      // modalSharingGroupOpen,
      // openSharingGroupModal,
      // tabs: [
      //   { name: "PROJECT", href: "/project", current: true },
      //   { name: "ALBUM", href: "/album", current: false },
      //   { name: "MODEL", href: "/model", current: false },
      // ],
      // toolbar: [
      //   {
      //     tooltipMsg: "Sharing group",
      //     icon: "icon-user",
      //   },
      // ],
    };
  },
  methods: {},
});
