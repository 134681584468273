
/* eslint-disable */
import CloudFun from '@cloudfun/core'
import { computed, defineComponent, onMounted, ref } from 'vue'
import { mapActions } from 'vuex'
import { v1 as uuid } from 'uuid'
import { Dialog, DialogOverlay, TransitionChild, TransitionRoot } from '@headlessui/vue'


export default defineComponent({
  components: {
    Dialog,
    DialogOverlay,
    // TransitionChild,
    // TransitionRoot,
  },
  setup() {
    const captchaToken = ref(uuid())
    const model = CloudFun.current?.model;
    const captchaUrl = computed(() => `${process.env.VUE_APP_API}/api/captcha?token=${captchaToken.value}`)
    const isOpen = ref(true);
    const errorMsg = ref('');
    return {
      isOpen,
      account: "",
      password: "",
      captcha: '',
      captchaToken,
      captchaUrl,
      rememberMe: true,
      errorMsg
    }
  },
  methods: {
    ...mapActions(['login']),
    formSubmit() {
      this.login({ account: this.account, password: this.password, rememberMe: this.rememberMe, captcha: this.captcha, captchaToken: this.captchaToken }).then(
        () => this.$router.push('/project'),
        failure => {
          this.$user = CloudFun.guest
          this.reloadCaptcha()
          this.errorMsg = failure.message || '帳號或密碼錯誤';
          // CloudFun.send('warning', failure.message || '帳號或密碼錯誤')
        }
      )
    },
    reloadCaptcha() {
      this.captchaToken = uuid()
      this.errorMsg = '';
    }
  }
})
