
import { computed, defineComponent, PropType, ref } from "@cloudfun/core";
import { useRouter } from "vue-router";
export default defineComponent({
  components: {},
  props: {
    toolbar: {
      type: Object,
      required: true,
      defaulut: {
        canCreate: false,
        canEdit: true,
        canDelete: true,
        canShare: false
      }
    },
    isActive: {
      type: Boolean,
      default: false
    }
  },
  emits: ["newItem", "share", "remove", "rename"],
  setup(props, { emit }) {

    const newItem = ()=>{
      emit('newItem')
      
    }
    const share = ()=>{
     if(props.isActive) emit('share')
    }
    const remove = ()=>{
     if(props.isActive) emit('remove')
    }
    const rename = ()=>{
     if(props.isActive) emit('rename')
    }
    return {
      newItem,
      share,
      remove,
      rename
    };
  },
});
