
/* eslint-disable */
import { defineComponent, PropType, ref, watch } from "vue";
import Cloudfun from "@cloudfun/core";
import {
  Popover,
  PopoverButton,
  PopoverPanel,
} from '@headlessui/vue'

export interface Tab {
  name: string;
  href: string;
  current: boolean;
}

export default defineComponent({
  name: "CfTabs",
  components: {
    Popover,
    PopoverButton,
    PopoverPanel,
  },
  props: {
    tabs: {
      type: Array as PropType<Tab[]>,
      required: true,
    },
  },
  setup(props) {
    const router = Cloudfun.current?.policy?.router;
    const selectedTab = ref(props.tabs.find(e => e.current)?.href);

    watch(selectedTab, value => {
      if (!value) return;
      router?.push(value);
    });
    return {
      selectedTab
    };
  },
});
